




















































































import axios from "@/services/axiosService";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ToastService from '@/services/toastService';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { IRegistrationDetails } from "@/interfaces/registrationDetails";
import { IRequestResponseMessage } from '@/interfaces/requestResponseMessage';

const Auth = namespace("Auth");


@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepRequestAccess extends Vue {

    private isRequestSubmitted = false;
    private showError = false;

    private loaFile: (File | null) = null;
    public tpirequestDetails: IRegistrationDetails = { Firstname: "", Lastname: "", Email: "", Telephone: "", CompanyName: "CompanyNameNotSet", AccountNumberFinanceReference: "", LOAFile: "", LetterOfAgreementFileName: "", AccessibleUntil: "", CaptchaToken: "", RegistrationTypeCustomer: false, RegistrationTypeTpi: true, RegistrationType: "Single", SessionGuid: "00000000-0000-0000-0000-000000000000", LOA: "", Actions: "", UserId: "", SefeFileData: "", SefeFileName: "" };

    private minimumAccessibleUntilDate = new Date();

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @Auth.Getter
    private getUserId!: string;
    
    @Auth.Getter
    private getUserEmail!: string;

    @Auth.Action
    private requestAccess!: ( requestDetails: IRegistrationDetails ) => Promise<any>; 

    public handleTPIRequest(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                return;
            }

            this.setTpiRequestDetails();

            if (this.tpirequestDetails.Email && this.tpirequestDetails.UserId && this.tpirequestDetails.AccountNumberFinanceReference) {
                this.requestAccess(this.tpirequestDetails).then(response => {
                    if ((response as boolean) != false) {
                        this.isRequestSubmitted = true;
                    }
                });
            }
            return;
        });
        return;
    }
    
    
    public setTpiRequestDetails(): void {
        this.tpirequestDetails.Email = this.getUserEmail;
        this.tpirequestDetails.UserId= this.getUserId;
    }

    public loadFile(file: File) {
        let ext = "";
        let validExt = true;
        if (file !== null) {
            ext = ((file as any).name.match(/.([^.]+)$/)[1]).toLowerCase();

            switch (ext) {
                case 'pdf':
                case 'doc':
                case 'docx':
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'heic':
                    break;
                default:
                    validExt = false;
            }

            if (validExt) {
                const reader = new FileReader();
                let model = this.tpirequestDetails;
                reader.onload = function () {
                    const loaContent = reader.result as string;
                    model.LOAFile = loaContent;
                    model.LetterOfAgreementFileName = file.name;
                }
                reader.readAsDataURL(file);
            } else {
                this.$bvToast.toast("Only .pdf, .doc, .docx, .jpg, .jpeg, .png, .heic files can be uploaded.", ToastService.ErrorMessageDefaults());
                this.loaFile = null;
            }
        }
    }

    public resetForm(): void {

        this.tpirequestDetails.AccountNumberFinanceReference = "";
        this.tpirequestDetails.LOAFile = "";
        this.tpirequestDetails.LetterOfAgreementFileName = "";
        this.tpirequestDetails.AccessibleUntil = "";

        this.loaFile = null;
        this.$validator.reset();
        this.isRequestSubmitted = false;
    }

}
