






import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

@Component
export default class BaseWizard extends Vue {

}
