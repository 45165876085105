






















import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue"
import WizardStepRequestAccess from "@/components/wizard/accessrequest/WizardStepRequestAccess.vue";

import { namespace } from 'vuex-class';

const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");

@Component({
    components: {
        BaseWizard,
        BasePage,
        WizardStepRequestAccess,
    },
})
export default class RequestAccess extends Vue {

    @Auth.Getter
    private canViewTPIRequestMenu!: boolean;

    private isExactActive = false;
    private watchProductSelection: any;
    private watchGasAccountSelection: any;
    private componentKey = 0;
    private transitionName = 'fade';


    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    beforeDestroy() {
        this.watchGasAccountSelection();
    }
}
